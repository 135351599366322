import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Shoulder Press 2-2-2-2-2 to a 2RM`}</p>
    <p>{`Pendlay Rows 2-2-2-2-2 to a 2RM`}</p>
    <p>{`then,`}</p>
    <p>{`21-15-9 reps each of:`}</p>
    <p>{`Ring Dips`}</p>
    <p>{`K2E’s`}</p>
    <p>{`rest 1:00`}</p>
    <p>{`21-15-9 reps each of:`}</p>
    <p>{`KBS’s (53/35)`}</p>
    <p>{`HSPU’s`}</p>
    <p>{`for total time.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`This Saturday is our free class at 9:00 & 10:00am so bring a
friend!`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      